<template>
  <Dashboard title="Match Page" no-padding="true">
    <div class="flex flex-col w-full">
      <div class="w-full relative h-[250px]">
        <div class="absolute left-0 top-0 z-10 h-full w-full map-linear-gradient"></div>
        <img class="absolute left-0 top-0 h-full w-full object-cover object-center select-none" :src="`/maps/${matchData?.map_name}_cs2.png`">
        <div class="w-full h-full px-9 pt-6 flex flex-col">
          <div class="w-full grid grid-cols-3 gap-4 items-start">
            <div v-if="matchData" class="flex gap-2 items-center">
              <img  class="w-5 h-5 z-50 select-none" :src="getPlatformLogo(matchData?.platform)">
              <span class="text-text-secondary z-50">{{ toTitleCase(matchData?.platform) }}</span>
              <span class="text-text-disabled z-50">•</span>
              <img class="w-5 h-5 z-50 select-none" :src="`/maps/${matchData?.map_name}_pin.png`">
              <span class="text-text-secondary z-50">{{ matchData?.map_name }}</span>
            </div>

            <div v-if="didUserWin !== null && scoreInfo" class="flex flex-col gap-3 items-center justify-center py-6">
              <div class="flex gap-2">
                <div v-if="didUserWin" class="text-sm rounded-md bg-[#12928333] text-success-400 px-2 py-1 z-50">Victory</div>
                <div v-else-if="scoreInfo[0] == scoreInfo[1]" class="text-sm rounded-md bg-[#8B000033] text-danger-300 px-2 py-1 z-50">Tie</div>
                <div v-else class="text-sm rounded-md bg-[#8B000033] text-danger-300 px-2 py-1 z-50">Defeat</div>
              </div>

              <div v-if="didUserWin" class="text-[48px] z-50 font-bold text-text-primary flex gap-2 items-center">
                <h1 :class="[scoreInfo[0] > scoreInfo[1] ? 'text-success-400' : '']">{{ scoreInfo[0] }}</h1>
                -
                <h1 :class="[scoreInfo[0] < scoreInfo[1] ? 'text-success-400' : '']">{{ scoreInfo[1] }}</h1>
              </div>

              <div v-if="!didUserWin" class="text-[48px] z-50 font-bold text-text-primary flex gap-2 items-center">
                <h1 :class="[scoreInfo[0] < scoreInfo[1] ? 'text-danger-400' : '']">{{ scoreInfo[0] }}</h1>
                -
                <h1 :class="[scoreInfo[0] > scoreInfo[1] ? 'text-danger-400' : '']">{{ scoreInfo[1] }}</h1>
              </div>

              <div class="text-text-secondary text-sm z-50">
                {{ moment(matchData?.date).format('h:mm A, MMM Do, YYYY') }}
              </div>
            </div>

            <div v-else-if="scoreInfo" class="flex flex-col gap-3 items-center justify-center py-6">
              <div class="text-[48px] z-50 font-bold text-text-primary flex gap-2 items-center">
                <h1>{{ scoreInfo[0] }}</h1>
                -
                <h1>{{ scoreInfo[1] }}</h1>
              </div>

              <div class="text-text-secondary text-sm z-50">
                {{ moment(matchData?.date).format('h:mm A, MMM Do, YYYY') }}
              </div>
            </div>

            <div class="flex gap-2 items-center justify-end">
              <button class="py-2.5 px-3 bg-gray-800 text-text-primary text-xs z-50 cursor-pointer hover:bg-gray-700 duration-300 rounded-lg">
                Watch replay
              </button>
              <button class="py-2.5 px-3 bg-gray-800 text-text-primary text-xs z-50 cursor-pointer hover:bg-gray-700 duration-300 rounded-lg">
                Share
              </button>
            </div>
          </div>

          <div class="w-full">
            <nav class="w-full flex flex-row relative gap-9 border-b border-gray-800 z-50">
              <div
                ref="overviewDiv"
                class="pb-4 text-center cursor-pointer flex gap-2 items-center w-full lg:w-auto justify-center lg:justify-start z-50"
                @click="updateActiveTab('overview')"
              >
                <img
                  src="../assets/icons/info-icon.svg"
                  :class="[activeTab === 'overview' ? 'brightness-200' : '']"
                  class="h-5 w-5 duration-300"
                  alt="Info icon"
                />
                <h3
                  class="leading-5 duration-300"
                  :class="[
                    activeTab === 'overview' ? 'text-text-primary' : 'text-text-secondary',
                  ]"
                >
                  Overview
                </h3>
              </div>
              <div
                ref="statsDiv"
                class="pb-4 text-center cursor-pointer flex gap-2 items-center w-full lg:w-auto justify-center lg:justify-start z-50"
                @click="updateActiveTab('statistics')"
              >
                <img
                  src="../assets/icons/stats-icon.svg"
                  :class="[activeTab === 'statistics' ? 'brightness-200' : '']"
                  class="h-5 w-5 duration-300"
                  alt="Stats icon"
                />
                <h3
                  class="leading-5 duration-300"
                  :class="[
                    activeTab === 'statistics' ? 'text-text-primary' : 'text-text-secondary',
                  ]"
                >
                  Statistics
                </h3>
              </div>
              <!-- <div
                ref="highlightsDiv"
                class="pb-4 text-center cursor-pointer flex gap-2 items-center w-full lg:w-auto justify-center lg:justify-start z-50"
                @click="updateActiveTab('highlights')"
              >
                <img
                  src="../assets/icons/globe.svg"
                  :class="[activeTab === 'highlights' ? 'brightness-200' : '']"
                  class="h-5 w-5 duration-300"
                  alt="Stats icon"
                />
                <h3
                  class="leading-5 duration-300"
                  :class="[
                    activeTab === 'highlights'
                      ? 'text-text-primary'
                      : 'text-text-secondary',
                  ]"
                >
                  Highlights
                </h3>
              </div> -->
              <div class="absolute flex-row md:flex bottom-0 z-50">
                <div
                  class="h-[2px] grow-transition"
                  :style="{ width: getSectionUnderlinePadding() + 'px' }"
                />
                <div
                  ref="sectionUnderline"
                  class="h-[2px] bg-primary-600 grow-transition"
                  :style="{ width: getSectionUnderlineWidth() + 'px' }"
                />
              </div>
            </nav>
          </div>
        </div>
      </div>
      <div class="px-9 py-6">
        <MatchOverview 
          v-if="activeTab === 'overview'" 
          :match-data="matchData" 
          @updateActiveTab="updateActiveTab"
          :active-user-two-lowest="activeUserTwoLowest"
          :active-user-match-data="activeUserMatchData"
          :active-user-data="activeUserData"
          @update-active-user-two-lowest="activeUserTwoLowest = $event"
          @update-active-user-match-data="activeUserMatchData = $event"
          @update-active-user-data="activeUserData = $event"
        />
        <MatchStatistics 
          v-else-if="activeTab === 'statistics'" 
          :match-data="matchData" 
          @updateActiveTab="updateActiveTab"
          :user-data="userData" 
          :selected-user="selectedUser" 
          :selected-stat="selectedStat" 
          :user-match-history="userMatchHistory"
          @update-user-data="userData = $event"
          @update-selected-user="selectedUser = $event"
          @update-selected-stat="selectedStat = $event"
          @update-user-match-history="userMatchHistory = $event"
          />
      </div>
    </div>
  </Dashboard>
</template>

<script setup>
import axios from "axios";
import Dashboard from "../components/layouts/DashboardLayout.vue";
import MatchOverview from "../components/Matches/MatchOverview.vue";
import MatchStatistics from "../components/Matches/MatchStatistics.vue";
import { computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useAuth } from "@websanova/vue-auth/src/v3.js";
import { data } from "autoprefixer";
import steamLogo from "../assets/icons/steam.svg";
import faceitLogo from "../assets/icons/faceit.svg";
import moment from "moment";

const activeTab = ref("overview");
const overviewDiv = ref();
const statsDiv = ref();
const highlightsDiv = ref();
const matchData = ref(null);
const sectionUnderlineWidth = ref(92);
const sectionUnderlinePadding = ref(0);
const route = useRoute();
const router = useRouter();
const auth = useAuth();
const user = ref(auth.user());
const userData = ref(null);
const selectedUser = ref(null);
const selectedStat = ref(null);
const userMatchHistory = ref(null);
const activeUserTwoLowest = ref(null);
const activeUserMatchData = ref(null);
const activeUserData = ref(null);
const didUserWin = ref(null);

const scoreInfo = computed(() => {
  if (matchData.value) {
    const scoreArray = parseScore(matchData.value.score);
    return scoreArray;
  }
});

function parseScore(scoreString) {
  return scoreString.split('-').map(Number);
}

function updateActiveTab(value) {
  activeTab.value = value;
  if (value === "overview") {
    router.push({ query: { } });
  } else {
    router.push({ query: { tab: value } });
  }
  updateUnderlineStyle();
}

function updateUnderlineStyle() {
  if (activeTab.value === "statistics") {
    sectionUnderlineWidth.value = statsDiv.value?.offsetWidth;
    sectionUnderlinePadding.value = statsDiv.value?.offsetLeft;
  } else if (activeTab.value === "highlights") {
    sectionUnderlineWidth.value = highlightsDiv.value?.offsetWidth;
    sectionUnderlinePadding.value = highlightsDiv.value?.offsetLeft;
  } else if (activeTab.value === "overview") {
    sectionUnderlineWidth.value = overviewDiv.value?.offsetWidth;
    sectionUnderlinePadding.value = overviewDiv.value?.offsetLeft;
  }
}

function getSectionUnderlineWidth() {
  return sectionUnderlineWidth.value;
}

function getSectionUnderlinePadding() {
  return sectionUnderlinePadding.value;
}

function getMatchData() {
  const token = auth.token();
  const tokenData = token.split("|");
  const accessToken = tokenData[1];
  const client = tokenData[2];
  const uid = tokenData[3];
  axios.get(`${import.meta.env.VITE_COACH_URL}/matches/${route.params.id}`, {
    headers: {
      'access-token': accessToken,
      'client': client,
      'uid': uid
    }
  }).then((response) => {
    matchData.value = response.data;
    
    if (!selectedUser.value) {
      const userSteamId = user.value.steam_id;
      const userIndex = response.data.players.findIndex((player) => player.player.steam_id == userSteamId);
      if (userIndex !== -1) {
        selectedUser.value = response.data.players[userIndex];
      } else {
        selectedUser.value = response.data.players[0];
      }
    }
    updateUserWinInfo();
    getActiveUserData();
  }).catch((error) => {
    // TODO: Show an error message to the user
    console.log(error);
  });
}

function updateUserWinInfo() {
  const userSteamId = user.value.steam_id;
  const userIndex = matchData.value.players.findIndex((player) => player.player.steam_id == userSteamId);
  if (userIndex !== -1) {
    didUserWin.value = matchData.value.players[userIndex].match_performance.last_round_team == matchData.value.winning_team;;
  } else {
    didUserWin.value = null;
  }
}

function getActiveUserData() {
  const userId = user.value.steam_id;
  const token = auth.token();
  const tokenData = token.split("|");
  const accessToken = tokenData[1];
  const client = tokenData[2];
  const uid = tokenData[3];

  const userIndex = matchData.value.players.findIndex((player) => player.player.steam_id == user.value.steam_id);
  if (userIndex === -1) {
    return;
  }

  axios.get(`${import.meta.env.VITE_COACH_URL}/players/${userId}/profile`, {
    headers: {
      'access-token': accessToken,
      'client': client,
      'uid': uid
    }
  }).then((response) => {
    activeUserData.value = response.data;
  }).catch((error) => {

  });
}

function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

function getPlatformLogo(platform) {
  if (platform === "faceit") {
    return faceitLogo;
  } else {
    return steamLogo;
  }
}

if (route.query.tab) {
  updateActiveTab(route.query.tab);
}

getMatchData();

</script>

<style scoped>
.map-linear-gradient {
  background: linear-gradient(180deg, #080A0D 0%, rgba(8, 10, 13, 0.8) 50%, #080A0D 100%);
}

.svg-filter {
  filter: invert(73%) sepia(27%) saturate(244%) hue-rotate(175deg) brightness(100%) contrast(85%);
}
</style>
