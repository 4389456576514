<script setup>
import DashboardLayout from "../components/layouts/DashboardLayout.vue";
import moment from "moment";
</script>

<template>
  <DashboardLayout title="Settings">
    <main class="rounded bg-gray-950 w-full flex flex-col gap-9">
      <h1 class="text-text-primary text-[36px] font-semibold">Settings</h1>
      <div class="flex gap-4 items-center">
        <img class="h-16 w-16 rounded-full" :src="imageURL" />
        <div class="flex flex-col gap-2">
          <h1 class="text-xl leading-6 font-semibold text-text-primary">
            {{ user?.steam_profile?.personaname || user.steam_id }}
          </h1>
          <h2 class="text-text-secondary text-sm">{{ user?.email }}</h2>
        </div>
      </div>
      <div class="relative w-full">
        <div class="pb-16">
          <div>
            <div class="flex flex-col gap-9">
              <!-- Description list with inline editing -->
              <div class="bg-gray-900 rounded-lg">
                <div
                  class="space-y-1 px-6 py-6 border-b border-gray-800 flex items-center"
                >
                  <div class="flex flex-col gap-1 flex-1">
                    <h3 class="text-lg leading-6 font-medium text-text-primary">
                      Profile
                    </h3>
                    <p class="max-w-2xl text-sm text-text-secondary">
                      This information will only be displayed publicly on our
                      leaderboards.
                    </p>
                  </div>
                  <router-link
                    :to="`user/${user.uuid}`"
                    class="text-text-primary bg-gray-800 text-xs font-medium rounded-md px-3 py-2.5 hover:bg-gray-700 hover:scale-[102%] duration-300"
                  >
                    View your Public Profile
                  </router-link>
                </div>
                <div class="px-6">
                  <dl class="divide-y divide-gray-800">
                    <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                      <dt class="text-text-primary">Name</dt>
                      <dd class="mt-1 flex text-text-secondary sm:mt-0 sm:col-span-2">
                        <span class="flex-grow">{{ username }}</span>
                      </dd>
                    </div>
                    <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
                      <dt class="text-text-primary my-auto">Avatar</dt>
                      <dd
                        class="mt-1 flex text-sm text-text-primary sm:mt-0 sm:col-span-2"
                      >
                        <span class="flex-grow">
                          <img class="h-8 w-8 rounded-full" :src="imageURL" alt="" />
                        </span>
                      </dd>
                    </div>
                    <div
                      class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5 items-center"
                    >
                      <dt class="text-text-primary my-auto">Email</dt>
                      <dd
                        class="mt-1 flex text-text-secondary sm:mt-0 sm:col-span-2 items-center"
                      >
                        <span class="flex-grow">{{ user.email }}</span>
                        <span class="ml-4 flex-shrink-0">
                          <button
                            @click="toggleEmailChangeModal"
                            class="bg-gray-800 rounded-lg text-xs text-text-primary px-3 py-2.5 hover:bg-gray-700 duration-300"
                          >
                            Change Email
                          </button>
                        </span>
                      </dd>
                    </div>
                    <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
                      <dt class="text-text-primary my-auto">Discord</dt>
                      <dd
                        class="mt-1 flex text-sm text-text-primary sm:mt-0 sm:col-span-2 items-center"
                      >
                        <span class="flex-grow"
                          ><button
                            class="px-8 py-1 border flex items-center gap-2 select-none border-transparent rounded-md shadow-sm text-sm font-medium text-text-primary bg-primary-600 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
                            @click="link_discord"
                            v-if="!discord_tag"
                          >
                            <img src="../assets/icons/discord-white.svg" alt="Discord Icon" class="w-4 h-4" />
                            Link your Discord
                          </button>
                          <span v-else class="flex flex-row"
                            >{{ discord_tag }}
                            <XIcon
                              class="w-4 h-4 text-gray-500 cursor-pointer"
                              @click="unlink_discord" /></span
                        ></span>
                        <span v-if="discord_tag" class="ml-4 flex-shrink-0">
                          <button
                            @click="syncDiscordRoles"
                            class="bg-gray-800 rounded-lg text-xs text-text-primary px-3 py-2.5 hover:bg-gray-700 duration-300"
                          >
                          <span v-if="syncingDiscord">Syncing...</span>
                          <span v-else>Sync Roles</span>
                          </button>
                        </span>
                      </dd>
                    </div>
                    <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
                      <dt class="text-text-primary my-auto">Steam Bot</dt>
                      <dd
                        class="mt-1 flex text-sm text-text-primary sm:mt-0 sm:col-span-2"
                      >
                        <span class=""
                          ><a
                            class="px-8 py-1 border flex items-center gap-2 select-none border-transparent rounded-md shadow-sm text-sm font-medium text-text-primary bg-primary-600 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
                            :href="steamBotLink"
                            target="_blank"
                            v-if="!user.has_steam_bot_added"
                          >
                            <img
                              src="../assets/icons/steam.svg"
                              alt="Steam Icon"
                              class="w-4 h-4"
                            />
                            Add to friends
                          </a>
                          <span v-else class="flex flex-row gap-2 text-success-500">
                            <CheckIcon class="w-4 h-4" />
                            Added to friends
                          </span></span
                        >
                      </dd>
                    </div>
                    <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
                      <dt class="text-text-primary my-auto">Wooting Detection</dt>
                      <dd
                        class="mt-1 flex text-sm text-text-primary sm:mt-0 sm:col-span-2"
                      >
                        <span v-if="!hidDetectionAvailable && !user.users_wooting" class="flex flex-row gap-2 text-danger-500">
                          <XIcon class="w-4 h-4" />
                          Wooting detection not available for your browser
                        </span>
                        <a
                          class="px-8 py-1 border flex items-center gap-2 select-none border-transparent rounded-md shadow-sm text-sm font-medium text-text-primary bg-primary-600 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-indigo-500"
                          @click="getKeyboard"
                          target="_blank"
                          v-else-if="!user.uses_wooting"
                        >
                          <img
                            src="../assets/icons/wooting_logo_white.svg"
                            alt="Steam Icon"
                            class="w-4 h-4"
                          />
                          Detect my Wooting
                        </a>
                        <span v-else class="flex flex-row gap-2 text-success-500">
                          <CheckIcon class="w-4 h-4" />
                          Wooting Detected
                        </span>
                      </dd>
                    </div>
                    <div
                      class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5 items-center"
                    >
                      <dt class="text-text-primary my-auto">Faceit Nickname</dt>
                      <dd
                        class="mt-1 flex text-sm text-text-primary sm:mt-0 sm:col-span-2"
                      >
                        <input
                          @input="faceitNickname = $event.target.value"
                          class="block w-1/3 rounded-md bg-gray-800 border-gray-700 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm text-text-secondary"
                          type="text"
                          :value="faceitNickname"
                        />
                        <Transition>
                          <button
                            type="button"
                            @click="saveFaceitNickname"
                            class="mt-3 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-primary-600 px-4 py-2 font-medium text-text-primary shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                          >
                            Save
                          </button>
                        </Transition>
                        <!-- <span class="ml-4 flex-shrink-0">
                          <button
                            type="button"
                            class="bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                          >
                            Update
                          </button>
                        </span> -->
                      </dd>
                    </div>
                    <div
                      class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5 items-center"
                    >
                      <dt class="text-text-primary my-auto flex items-center">
                        Steam Match Code
                        <a
                          href="https://help.steampowered.com/en/wizard/HelpWithGameIssue/?appid=730&issueid=128&ref=refrag.gg"
                          target="_blank"
                          class="ml-2 text-text-secondary underline text-xs"
                          >Get code here</a
                        >
                      </dt>
                      <dd
                        v-if="steamMatchCode !== 'CSGO - ***** - ***** - ***** - *****'"
                        class="mt-1 flex text-sm text-text-primary sm:mt-0 sm:col-span-2"
                      >
                        <input
                          @input="steamMatchCode = $event.target.value"
                          placeholder="CSGO - XXXXX - XXXXX - XXXXX - XXXXX"
                          class="block w-1/3 rounded-md bg-gray-800 border-gray-700 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm text-text-secondary"
                          type="text"
                          :value="steamMatchCode"
                        />
                        <Transition>
                          <button
                            type="button"
                            @click="saveSteamMatchCode"
                            class="mt-3 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-primary-600 px-4 py-2 font-medium text-text-primary shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                          >
                            Save
                          </button>
                        </Transition>
                      </dd>
                      <dd
                        v-else
                        class="mt-1 flex text-sm text-text-primary sm:mt-0 sm:col-span-2 items-center"
                      >
                        <h1 class="text-text-secondary">{{ steamMatchCode }}</h1>
                        <button
                          type="button"
                          @click="clearMatchCode"
                          class="mt-3 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-primary-600 px-2 py-2 font-medium text-text-primary shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                        >
                          <img
                            src="../assets/icons/trash.svg"
                            alt="Clear Icon"
                            class="w-4 h-4 brightness-200"
                          />
                        </button>
                      </dd>
                    </div>
                    <div
                      class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5 items-center"
                    >
                      <dt
                        class="text-text-primary my-auto flex items-center flex items-center"
                      >
                        Steam Authentication Code
                        <a
                          href="https://help.steampowered.com/en/wizard/HelpWithGameIssue/?appid=730&issueid=128&ref=refrag.gg"
                          target="_blank"
                          class="ml-2 text-text-secondary underline text-xs"
                          >Get code here</a
                        >
                      </dt>
                      <dd
                        v-if="authenticationCode !== user.match_auth_token"
                        class="mt-1 flex text-sm text-text-primary sm:mt-0 sm:col-span-2"
                      >
                        <input
                          @input="authenticationCode = $event.target.value"
                          placeholder="XXX - XXXXX - XXXX"
                          class="block w-1/3 rounded-md bg-gray-800 border-gray-700 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm text-text-secondary"
                          type="text"
                          :value="authenticationCode"
                        />
                        <Transition>
                          <button
                            type="button"
                            @click="saveSteamAuthCode"
                            class="mt-3 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-primary-600 px-4 py-2 font-medium text-text-primary shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                          >
                            Save
                          </button>
                        </Transition>
                      </dd>
                      <dd
                        v-else
                        class="mt-1 flex text-sm text-text-primary sm:mt-0 sm:col-span-2 items-center"
                      >
                        <h1 class="text-text-secondary">{{ authenticationCode }}</h1>
                        <button
                          type="button"
                          @click="clearSteamAuthCode"
                          class="mt-3 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-primary-600 px-2 py-2 font-medium text-text-primary shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                        >
                          <img
                            src="../assets/icons/trash.svg"
                            alt="Clear Icon"
                            class="w-4 h-4 brightness-200"
                          />
                        </button>
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>

              <div class="bg-gray-900 rounded-lg">
                <div class="space-y-1 px-6 py-6 border-b border-gray-800">
                  <h3 class="text-lg leading-6 font-medium text-text-primary">
                    Email Settings
                  </h3>
                  <p class="max-w-2xl text-sm text-text-secondary">
                    Manage your email settings.
                  </p>
                </div>

                <div class="flex flex-col w-full divide-y divide-gray-800 px-6">
                  <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt class="text-sm font-medium text-text-primary">
                      Receive Emails for Access Codes
                      <p class="max-w-2xl text-xs text-text-secondary font-normal">
                        Receive emails whenever you get a free access code.
                      </p>
                    </dt>

                    <dd
                      class="mt-1 flex text-sm text-text-primary sm:mt-0 sm:col-span-2 items-center"
                    >
                      <input
                        type="checkbox"
                        :value="accessCodeEmailsVal"
                        v-model="accessCodeEmailsVal"
                        v-on:change="saveEmailSettings()"
                        class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                    </dd>
                  </div>

                  <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt class="text-sm font-medium text-text-primary">
                      Receive Emails for Notifications
                      <p class="max-w-2xl text-xs text-text-secondary font-normal">
                        Receive emails whenever you get a notification.
                      </p>
                    </dt>
                    <dd
                      class="mt-1 flex text-sm text-text-primary sm:mt-0 sm:col-span-2 items-center"
                    >
                      <input
                        type="checkbox"
                        :value="notificationEmailsVal"
                        v-model="notificationEmailsVal"
                        v-on:change="saveEmailSettings()"
                        class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                    </dd>
                  </div>

                  <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt class="text-sm font-medium text-text-primary">
                      Receive Emails for our Mailing List
                      <p class="max-w-2xl text-xs text-text-secondary font-normal">
                        Receive emails to stay up to date with Refrag!
                      </p>
                    </dt>
                    <dd
                      class="mt-1 flex text-sm text-text-primary sm:mt-0 sm:col-span-2 items-center"
                    >
                      <input
                        type="checkbox"
                        :value="emailSignUpVal"
                        v-model="emailSignUpVal"
                        v-on:change="saveEmailSettings()"
                        class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                    </dd>
                  </div>
                </div>
              </div>

              <div class="bg-gray-900 rounded-lg">
                <div class="space-y-1 px-6 py-6 border-b border-gray-800">
                  <h3 class="text-lg leading-6 font-medium text-text-primary">Play</h3>
                  <p class="max-w-2xl text-sm text-text-secondary">
                    Manage how you play on our platform.
                  </p>
                </div>
                <div class="px-6">
                  <dl class="divide-y divide-gray-800">
                    <div
                      class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 items-center"
                    >
                      <dt class="font-medium text-text-primary">
                        Server Language
                        <p class="max-w-2xl text-xs text-text-secondary font-normal">
                          Set the language for all server texts.
                        </p>
                      </dt>
                      <dd class="mt-1 flex text-text-secondary sm:mt-0 sm:col-span-2">
                        <Menu
                          as="div"
                          class="relative inline-block text-left w-full lg:w-auto"
                        >
                          <MenuButton
                            class="relative w-[300px] disabled:bg-gray-800 disabled:text-text-disabled inline-flex h-8 items-center px-2 py-2 border border-gray-700 shadow-sm text-sm font-medium rounded-md text-text-primary bg-gray-800 hover:bg-gray-700"
                          >
                            <div class="flex-1 text-left">
                              {{ selectedLanguage.name }}
                            </div>
                            <ChevronDownIcon
                              class="-mr-1 ml-4 h-5 w-5"
                              aria-hidden="true"
                            />
                          </MenuButton>

                          <transition
                            enter-active-class="transition ease-out duration-100"
                            enter-from-class="transform opacity-0 scale-95"
                            enter-to-class="transform opacity-100 scale-100"
                            leave-active-class="transition ease-in duration-75"
                            leave-from-class="transform opacity-100 scale-100"
                            leave-to-class="transform opacity-0 scale-95"
                          >
                            <MenuItems
                              class="absolute right-0 z-50 mt-2 w-[300px] origin-top-right divide-y divide-gray-100 rounded-md bg-gray-800 border border-gray-700 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                            >
                              <div class="py-1 flex flex-col px-4">
                                <MenuItem
                                  :key="language"
                                  v-for="language in languages"
                                  v-slot="{ active }"
                                  @click="selectLanguage(language)"
                                >
                                  <div
                                    :class="[
                                      active
                                        ? 'text-text-primary'
                                        : 'text-text-secondary',
                                      'group flex items-center py-2 text-sm w-full justify-start cursor-pointer',
                                    ]"
                                  >
                                    {{ language.name }}
                                    <CheckIcon
                                      v-if="language.name === selectedLanguage?.name"
                                      class="h-5 w-5 ml-auto text-primary-400"
                                    />
                                  </div>
                                </MenuItem>
                              </div>
                            </MenuItems>
                          </transition>
                        </Menu>
                      </dd>
                    </div>
                    <div
                      class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5 items-center"
                    >
                      <dt class="text-text-primary">Steam ID</dt>
                      <dd
                        class="mt-1 flex text-text-secondary sm:mt-0 sm:col-span-2 items-center"
                      >
                        <span class="flex-grow">{{ user.steam_id }}</span>
                        <span
                          v-if="showChangeSteamAccount()"
                          class="ml-4 flex-shrink-0 flex items-start space-x-4"
                        >
                          <button
                            @click="showSteamClearConfirmation()"
                            class="bg-gray-800 rounded-lg text-xs text-text-primary px-3 py-2.5"
                          >
                            Change Steam Account
                          </button>
                        </span>
                      </dd>
                    </div>
                    <div
                      class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5"
                      v-if="subscriptions.length == 0"
                    >
                      <dt class="text-text-primary">Subscription</dt>
                      <dd class="mt-1 flex text-text-secondary sm:mt-0 sm:col-span-2">
                        <span class="flex-grow" v-if="user.access_level === 3">
                          Team Tier Access
                          <span v-if="user.has_canceled_sub"> (Cancelled) </span>
                          <span v-if="user.has_active_trial_code">
                            Trial (ends
                            {{ moment(user.trial_expires_at).format("LLLL") }})
                          </span>
                          <span v-else-if="user.subscription_end_date">
                            (ends {{ moment(user.subscription_end_date).format("LL") }})
                          </span>
                        </span>
                        <span class="flex-grow" v-else-if="user.access_level === 2">
                          Competitor Tier Access
                          <span v-if="user.has_canceled_sub"> (Cancelled) </span>
                          <span v-if="user.has_active_trial_code">
                            Trial (ends
                            {{ moment(user.trial_expires_at).format("LLLL") }})
                          </span>
                          <span v-else-if="user.subscription_end_date">
                            (ends {{ moment(user.subscription_end_date).format("LL") }})
                          </span>
                        </span>
                        <span class="flex-grow" v-else-if="user.access_level === 1">
                          Player Tier Access
                          <span v-if="user.has_canceled_sub"> (Cancelled) </span>
                          <span v-if="user.has_active_trial_code">
                            Trial (ends
                            {{ moment(user.trial_expires_at).format("LLLL") }})
                          </span>
                          <span v-else-if="user.subscription_end_date">
                            (ends {{ moment(user.subscription_end_date).format("LL") }})
                          </span>
                        </span>
                        <span class="flex-grow" v-else>Free Access</span>
                        <span
                          class="ml-4 flex-shrink-0 flex items-start space-x-4"
                          v-if="
                            user.base_access &&
                            user.has_active_subscription &&
                            !user.has_canceled_sub
                          "
                        >
                          <a
                            :href="user.update_subscription_url || '#'"
                            class="bg-primary-600 px-5 py-2 rounded-md font-medium text-text-primary hover:text-text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            target="_blank"
                            v-if="user.uses_paddle"
                          >
                            Update
                          </a>
                          <a
                            :href="user.cancel_subscription_url || '#'"
                            class="bg-primary-600 px-5 py-2 rounded-md font-medium text-text-primary hover:text-text-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            target="_blank"
                            v-if="user.uses_paddle"
                          >
                            Cancel
                          </a>
                        </span>
                      </dd>
                    </div>
                    <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                      <dt class="font-medium text-text-primary sm:col-span-1 my-auto">
                        Redeem Code
                      </dt>
                      <dd
                        class="mt-1 flex text-sm text-text-primary sm:mt-0 sm:col-span-2"
                      >
                        <input
                          @input="codeInput = $event.target.value"
                          class="block w-1/3 rounded-md border-gray-700 bg-gray-800 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm text-text-secondary"
                          type="text"
                          placeholder="Code"
                          :value="codeInput"
                        />
                        <Transition>
                          <button
                            type="button"
                            @click="redeemCode"
                            class="mt-3 inline-flex w-60 items-center justify-center rounded-md border border-transparent bg-primary-600 px-4 py-2 font-medium text-text-primary shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                          >
                            Redeem
                          </button>
                        </Transition>
                        <!-- <span class="ml-4 flex-shrink-0">
                          <button
                            type="button"
                            class="bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                          >
                            Update
                          </button>
                        </span> -->
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
              <div class="bg-gray-900 rounded-lg" v-if="subscriptions.length > 0">
                <div class="space-y-1 px-6 py-6 border-b border-gray-800">
                  <h3 class="text-lg leading-6 font-medium text-text-primary">
                    Subscriptions
                  </h3>
                  <p class="max-w-2xl text-sm text-text-secondary">
                    Manage your subscriptions.
                  </p>
                </div>
                <div class="py-4 flex flex-col">
                  <div ref="codes" class="flex w-full px-6">
                    <table class="w-full">
                      <thead class="border-b border-gray-800">
                        <tr>
                          <th
                            scope="col"
                            class="py-3.5 pl-6 text-left pr-3 text-xs font-light text-text-secondary uppercase tracking-[.5px]"
                          >
                            Subscription Type
                          </th>
                          <th
                            scope="col"
                            class="px-3 py-3.5 text-left text-xs font-light text-text-secondary uppercase tracking-[.5px]"
                          >
                            Status
                          </th>
                          <th
                            scope="col"
                            class="px-3 py-3.5 text-left text-xs font-light text-text-secondary uppercase tracking-[.5px]"
                          >
                            Period
                          </th>
                          <th
                            scope="col"
                            class="px-3 py-3.5 text-left text-xs font-light text-text-secondary uppercase tracking-[.5px]"
                          >
                            Cost
                          </th>
                          <th
                            scope="col"
                            class="px-3 py-3.5 text-left text-xs font-light text-text-secondary uppercase tracking-[.5px]"
                          >
                            ID
                          </th>
                          <th
                            scope="col"
                            class="px-3 py-3.5 text-left text-xs font-light text-text-secondary uppercase tracking-[.5px]"
                          >
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody class="">
                        <tr
                          v-for="subscription in subscriptions"
                          :key="subscription"
                          class="text-center odd:bg-gray-900"
                        >
                          <td
                            class="whitespace-nowrap py-3 pl-6 pr-3 text-sm text-text-primary text-left flex gap-2 items-center"
                          >
                            <h1>
                              {{ subscriptionName(subscription) }}
                            </h1>
                          </td>
                          <td
                            class="whitespace-nowrap px-3 py-3 text-sm text-left"
                            :class="subscriptionStatusClass(subscription)"
                          >
                            {{ subscriptionStatusText(subscription) }}
                          </td>
                          <td
                            class="whitespace-nowrap px-3 py-3 text-sm text-text-primary text-left"
                          >
                            {{ subscriptionPeriod(subscription) }}
                          </td>
                          <td
                            class="whitespace-nowrap px-3 py-3 text-sm text-text-primary text-left"
                          >
                            {{ getCurrencySymbol(subscription?.currency) }}
                            {{ subscription.cost }}
                          </td>
                          <td
                            class="whitespace-nowrap px-3 py-3 text-sm text-text-primary text-left italic"
                          >
                            {{ subscription.id }}
                          </td>
                          <td
                            class="whitespace-nowrap px-3 py-3 text-sm text-text-primary text-left"
                          >
                            <button
                              class="text-red-400 underline underline-offset-4"
                              target="_blank"
                              v-if="subscription.status == 'active'"
                              @click="toggleUnsubscribeModal(subscription?.id)"
                            >
                              Cancel
                            </button>
                            <button
                              v-else-if="subscription.status == 'non_renewing'"
                              class="text-primary-400 underline underline-offset-4"
                              @click="reactivate_xsolla_subscription(subscription?.id)"
                            >
                              Reactivate
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="bg-gray-900 rounded-lg">
                <div class="space-y-1 px-6 py-6 border-b border-gray-800">
                  <h3 class="text-lg leading-6 font-medium text-text-primary">
                    My Codes
                  </h3>
                  <p class="max-w-2xl text-sm text-text-secondary">
                    Subscribers get 1 week Free access codes every month to give to
                    friends
                  </p>
                </div>
                <div class="py-4 flex flex-col">
                  <div ref="codes" class="flex w-full px-6">
                    <table class="w-full">
                      <thead class="border-b border-gray-800">
                        <tr>
                          <th
                            scope="col"
                            class="py-3.5 pl-6 text-left pr-3 text-xs font-light text-text-secondary uppercase tracking-[.5px]"
                          >
                            Code
                          </th>
                          <th
                            scope="col"
                            class="px-3 py-3.5 text-left text-xs font-light text-text-secondary uppercase tracking-[.5px]"
                          >
                            Length
                          </th>
                          <th
                            scope="col"
                            class="px-3 py-3.5 text-left text-xs font-light text-text-secondary uppercase tracking-[.5px]"
                          >
                            Tier
                          </th>
                          <th
                            scope="col"
                            class="px-3 py-3.5 text-left text-xs font-light text-text-secondary uppercase tracking-[.5px]"
                          >
                            Type
                          </th>
                        </tr>
                      </thead>
                      <tbody class="">
                        <tr
                          v-for="code in trialCodes"
                          :key="code"
                          class="text-center odd:bg-gray-900"
                        >
                          <td
                            class="whitespace-nowrap py-3 pl-6 pr-3 text-sm text-text-primary text-left flex gap-2 items-center"
                          >
                            <h1>{{ code.code }}</h1>
                            <div
                              v-clipboard:copy="`${code.code}`"
                              v-clipboard:success="copySucceeded"
                            >
                              <img
                                src="../assets/icons/copy.svg"
                                alt="Copy Icon"
                                class="w-4 h-4 cursor-pointer brightness-75 hover:scale-105 hover:brightness-100 duration-300"
                              />
                            </div>
                          </td>
                          <td
                            class="whitespace-nowrap px-3 py-3 text-sm text-text-primary text-left"
                          >
                            {{ code.duration_in_days }} Days
                          </td>
                          <td
                            class="whitespace-nowrap px-3 py-3 text-sm text-text-primary text-left"
                          >
                            {{ code.tier }}
                          </td>
                          <td
                            class="whitespace-nowrap px-3 py-3 text-sm text-text-primary text-left"
                          >
                            {{ getCodeType(code) }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </DashboardLayout>
  <UnsubscribeModal
    v-bind:showUnsubscribeModal="showUnsubscribeModal"
    :subscription_id="modalSubscription"
  />
  <ModalWindow :open="showSteamClearModal">
    <div class="flex flex-col gap-6">
      <h1 class="text-text-primary text-[24px] font-semibold flex gap-3 items-center">
        <img src="../assets/icons/error.svg" alt="Steam Icon" class="w-8 h-8" />
        Change Steam Account
      </h1>
      <p class="text-text-secondary text-sm">
        Are you sure you want to change your Steam account? You can only do this every 30
        days!
      </p>
      <div class="flex gap-4">
        <button
          @click="unlinkSteamAccount"
          class="bg-danger-400 text-danger-950 px-4 py-2 rounded-md font-semibold hover:bg-danger-300 w-full duration-300"
        >
          Yes, update Steam
        </button>
        <button
          @click="showSteamClearModal = false"
          class="bg-gray-800 text-text-primary px-4 py-2 rounded-md font-medium hover:bg-gray-700 w-full duration-300"
        >
          Cancel
        </button>
      </div>
    </div>
  </ModalWindow>
  <ModalWindow :open="showChangeEmailModal">
    <h2 class="text-2xl text-text-primary font-semibold flex gap-2">
      <div class="w-8 h-8 rounded-full bg-gray-800 flex items-center justify-center">
        <img class="w-4" src="../assets/icons/error.svg" alt="Refrag Icon" />
      </div>
      Change email
    </h2>
    <h3 class="text-text-secondary mt-3">
      By clicking the button below, you will be sent an email with instructions on how to
      change your email.
    </h3>
    <div class="flex flex-row justify-end mt-6 w-full">
      <button
        class="bg-danger-500 hover:bg-danger-400 duration-300 px-4 py-2 rounded w-full"
        @click="updateEmail"
      >
        Yes, change email
      </button>
      <button
        class="bg-gray-800 hover:bg-gray-700 duration-300 px-4 py-2 ml-2 rounded w-full text-text-primary"
        @click="showChangeEmailModal = false"
      >
        No, don't change email
      </button>
    </div>
  </ModalWindow>
</template>

<script>
import { ref } from "vue";
import axios from "axios";
import icon from "../assets/logos/refrag-icon-button.svg";
import UnsubscribeModal from "../components/UnsubscribeModal.vue";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import ModalWindow from "../components/ModalWindow.vue";
import { XIcon, CheckIcon, ChevronDownIcon } from "@heroicons/vue/solid";
import { mapMutations } from "vuex";

const tabs = [{ name: "General", href: "#", current: true }];

export default {
  head: {
    title: "Refrag | Settings",
  },
  components: {
    UnsubscribeModal,
    XIcon,
  },
  setup() {
    const automaticTimezoneEnabled = ref(true);
    const autoUpdateApplicantDataEnabled = ref(false);

    return {
      automaticTimezoneEnabled,
      autoUpdateApplicantDataEnabled,
      tabs,
    };
  },
  data() {
    return {
      syncingDiscord: false,
      userIsSure: false,
      faceitNickname: "",
      codeInput: "",
      showUnsubscribeModal: false,
      trialCodes: [],
      accessCodeEmailsVal: null,
      notificationEmailsVal: null,
      emailSignUpVal: null,
      steamMatchCode: "",
      authenticationCode: "",
      subscriptions: [],
      modalSubscription: null,
      showSteamClearModal: false,
      showChangeEmailModal: false,
      userSettings: {},
      steamBotLink: null,
      websocket: null,
      subscription: null,
      languages: [
        { name: "English", code: "en" },
        { name: "Português", code: "pt_t" },
        { name: "Danish", code: "dk" },
        { name: "Chinese", code: "cn" },
        { name: "Deutsch", code: "de" },
        { name: "Polish", code: "pl" },
      ],
      localSelectedLanguage: null,
    };
  },
  computed: {
    hidDetectionAvailable() {
      return !!navigator?.hid;
    },
    user() {
      return { ...this.$auth.user(), ...this.userSettings };
    },
    emailSettings() {
      return this.user.email_settings;
    },
    username() {
      if (this.user.steam_profile) {
        return this.user.steam_profile.personaname;
      }
      return this.user.steam_id;
    },
    discord_tag() {
      return this.user.discord_tag;
    },
    imageURL() {
      if (this.user.steam_profile) {
        return this.user.steam_profile.avatarfull;
      }
      return icon;
    },
    selectedLanguage() {
      if (this.localSelectedLanguage) {
        return this.localSelectedLanguage;
      } else if (!this.user.language) {
        return this.languages[0];
      }
      return this.languages.find((language) => language.code === this.user.language);
    },
  },
  methods: {
    ...mapMutations(["showMessage", "hideMessage"]),
    getSteamBot() {
      axios({
        method: "get",
        url: "/steam_bots/bot",
      }).then((response) => {
        this.steamBotLink = response.data.data.invite_link;
      });
    },
    subscribeToSteamBot() {
      if (this.subscription) return;

      this.websocket = this.$cable.connect();
      this.subscription = this.websocket.subscriptions.create(
        { channel: "SteamBotChannel", user_id: this.user.id },
        {
          received: (data) => {
            if (data.event === "steam_bot_added") {
              this.$auth.fetch();
            }
          },
        }
      );
    },
    selectLanguage(language) {
      this.localSelectedLanguage = language;
      axios({
        method: "put",
        url: "/users/update_user",
        data: {
          language: language.code,
        },
      }).then(() => {
        this.$auth.fetch();
      });
    },
    getRecurringText(subscription) {
      switch (subscription.duration_in_months) {
        case 1:
          return "Monthly";
        case 3:
          return "Quarterly";
        case 6:
          return "Bi-Annually";
        case 12:
          return "Annually";
        default:
          return "Unknown";
      }
    },
    getCurrencySymbol(currency) {
      if (!currency) return;
      return (0)
        .toLocaleString("en-US", {
          style: "currency",
          currency: currency,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })
        .replace(/\d/g, "")
        .trim();
    },
    toggleEmailChangeModal() {
      this.showChangeEmailModal = !this.showChangeEmailModal;
    },
    showSteamClearConfirmation() {
      this.showSteamClearModal = true;
    },
    showChangeSteamAccount() {
      return (
        !this.user.steam_id_last_changed_at ||
        moment().diff(this.user.steam_id_last_changed_at, "days") >= 30
      );
    },
    updateEmail() {
      axios({
        method: "post",
        url: "/users/request_email_change",
      }).then((res) => {
        this.showChangeEmailModal = false;
        this.showMessage({
          messageType: "success",
          messageText: "Email sent with instructions.",
        });
        setTimeout(() => {
          this.hideMessage();
        }, 3000);
      });
    },
    unlinkSteamAccount() {
      axios({
        method: "put",
        url: "/users/clear_steam_id",
      }).then((res) => {
        this.$auth.fetch();
        this.$router.push({ name: "Dashboard" });
      });
    },
    subscriptionName(subscription) {
      const name =
        subscription.subscription_tier.name.charAt(0).toUpperCase() +
        subscription.subscription_tier.name.slice(1);
      return `${name} (${this.getRecurringText(subscription)})`;
    },
    subscriptionStatusText(subscription) {
      if (subscription.status === "active") {
        return "Active";
      }
      if (subscription.status == "non_renewing") {
        return "Non-Renewing";
      }
      return "Cancelled";
    },
    subscriptionStatusClass(subscription) {
      if (subscription.status === "active") {
        return "text-green-500";
      }
      if (subscription.status == "non_renewing") {
        return "text-yellow-500";
      }
      return "text-red-500";
    },
    subscriptionPeriod(subscription) {
      return `${moment(subscription.date_create).format("LL")} - ${moment(
        subscription.date_next_charge
      ).format("LL")}`;
    },
    getCodeType(code) {
      if (code.ignore_limit) return "Access";
      return "Trial";
    },
    copySucceeded() {
      this.showMessage({
        messageType: "success",
        messageText: "Code Copied to Clipboard",
      });
      setTimeout(() => {
        this.hideMessage();
      }, 3000);
    },
    async getKeyboard() {
      const device = await navigator?.hid?.requestDevice({
        filters: [
          {
            vendorId: 0x31e3,
          },
        ],
      });
      if (device) {
        this.updateUser({ uses_wooting: true }).then(() => {
          this.$auth.fetch();
        });
      }
    },
    async getTrialCodes() {
      axios({
        method: "get",
        url: "trial_codes",
        data: {},
      }).then((data) => {
        this.trialCodes = data.data;
      });
    },
    async getSubscriptions() {
      axios({
        method: "get",
        url: "subscriptions/my_subscriptions",
        data: {},
      }).then((data) => {
        this.subscriptions = data.data;
      });
    },
    link_discord() {
      axios({
        method: "get",
        url: "/discord/link_discord",
        data: {},
      }).then((data) => {
        window.location = data.data.url;
      });
    },
    unlink_discord() {
      axios({
        method: "post",
        url: "/discord/unlink_discord",
        data: {},
      }).then(() => {
        this.$auth.fetch();
      });
    },
    cancel_xsolla_subscription() {
      const confirmed = confirm("Are you sure you want to cancel your subscription?");
      if (!confirmed) return;

      this.$gtm.trackEvent({
        event: "cancel_subscription",
      });
    },
    reactivate_xsolla_subscription(subscription_id) {
      this.$gtm.trackEvent({
        event: "reactivate_subscription",
      });

      axios({
        method: "post",
        url: `/subscriptions/${subscription_id}/reactivate`,
        data: {},
      }).then(() => {
        window.location.reload();
      });
    },
    toggleUnsubscribeModal(subscription_id) {
      this.modalSubscription = subscription_id;
      this.showUnsubscribeModal = !this.showUnsubscribeModal;
    },
    redeemCode() {
      axios({
        url: "/trial_codes/redeem",
        method: "POST",
        headers: {},
        data: {
          code: this.codeInput,
        },
      })
        .then((res) => {
          this.$auth.fetch();
          this.showMessage({
            messageType: "success",
            messageText: "Trial Code Redeemed Successfully",
          });
        })
        .catch((err) => {
          this.showMessage({
            messageType: "error",
            messageText: err.response.data.errors[0],
          });
        });
    },
    saveFaceitNickname() {
      this.updateUser({ faceit_nickname: this.faceitNickname })
        .then(() => {
          this.$auth.fetch();
          this.showMessage({
            messageType: "success",
            messageText: "Updated Faceit Nickname Successfully",
          });
        })
        .catch((err) => {
          this.showMessage({
            messageType: "error",
            messageText: err.response.data.message,
          });
        });
    },
    async updateUser(data) {
      return axios({
        method: "put",
        url: "/users/update_user",
        data: data,
      });
    },
    saveSteamMatchCode() {
      axios({
        method: "post",
        url: "/matches",
        data: {
          match: {
            match_platform: "valve",
            match_token: this.steamMatchCode,
          },
        },
      })
        .then(() => {
          this.$auth.fetch();
          this.showMessage({
            messageType: "success",
            messageText: "Updated Steam Match Code Successfully",
          });
        })
        .catch((err) => {
          this.showMessage({
            messageType: "error",
            messageText: `Match token ${err.response.data.match_token[0]}`,
          });
        });
    },
    saveSteamAuthCode() {
      axios({
        method: "put",
        url: "/users/update_user",
        data: {
          match_auth_token: this.authenticationCode,
        },
      }).then((res) => {
        this.$auth.fetch();
        this.authenticationCode = res.data.data.match_auth_token;
        this.showMessage({
          messageType: "success",
          messageText: "Updated Steam Auth Code Successfully",
        });
      });
    },
    clearMatchCode() {
      this.steamMatchCode = "";
    },
    clearSteamAuthCode() {
      axios({
        method: "put",
        url: "/users/update_user",
        data: {
          match_auth_token: "",
        },
      }).then((res) => {
        this.$auth.fetch();
        this.authenticationCode = "";
        this.showMessage({
          messageType: "success",
          messageText: "Cleared Steam Auth Code Successfully",
        });
      });
    },
    getUserEmailSettings() {
      this.accessCodeEmailsVal =
        this.user.email_settings.receive_email_for_access_codes || false;
      this.notificationEmailsVal =
        this.user.email_settings.receive_email_for_notifications || false;
      this.emailSignUpVal = this.user.email_settings.email_sign_up || false;
    },
    syncDiscordRoles() {
      if(this.syncingDiscord) return;
      this.syncingDiscord = true;
      axios({
        method: "post",
        url: "/users/sync_roles",
      }).then(() => {
        this.showMessage({
          messageType: "success",
          messageText: "Discord Roles Synced Successfully",
        });
        setTimeout(() => {
          this.hideMessage();
        }, 3000);
      }).finally(() => {
        this.syncingDiscord = false;
      });
    },
    saveEmailSettings() {
      axios({
        method: "put",
        url: "/users/update_user",
        data: {
          receive_email_for_access_codes: this.accessCodeEmailsVal,
          receive_email_for_notifications: this.notificationEmailsVal,
          email_sign_up: this.emailSignUpVal,
        },
      }).then(() => {
        this.$auth.fetch();
        this.showMessage({
          messageType: "success",
          messageText: "Updated Email Settings Successfulyy",
        });
      });
    },
  },
  mounted() {
    axios({
      method: "get",
      url: "/users/my_settings",
    }).then((res) => {
      this.userSettings = res.data;
      if (this.user.faceit_nickname && this.user.faceit_nickname.length > 0) {
        this.faceitNickname = this.user.faceit_nickname || "";
      }

      if (this.user.match_auth_token && this.user.match_auth_token.length > 0) {
        this.user.match_auth_token === "********"
          ? (this.authenticationCode = "")
          : (this.authenticationCode = this.user.match_auth_token);
      }

      if (this.user.has_premier_matches) {
        this.steamMatchCode = "CSGO - ***** - ***** - ***** - *****";
      }

      this.getTrialCodes();
      this.getSubscriptions();

      this.getUserEmailSettings();
      this.getSteamBot();
      this.subscribeToSteamBot();
    });

    if (this.$route.hash === "#trial_codes")
      this.$refs.codes.scrollIntoView({ block: "start", behavior: "smooth" });
  },
};
</script>

<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.2s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
