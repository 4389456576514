<template>
    <div @click="emit('updateOnboardingStep', { value: props.currentStep - 1 })" class="cursor-pointer hover:scale-105 duration-300 rounded-md bg-gray-800 px-3 py-2.5 text-text-primary flex justify-center items-center text-xl absolute top-8 left-4 lg:left-16">
        <img class="w-4 mr-1" src="../../assets/icons/leftarrow.svg">
        <h1 class="text-xs text-text-primary">Back</h1>
    </div>
    <div @click="emit('updateOnboardingStep', { value: props.currentStep + 1 })" class="cursor-pointer hover:scale-105 duration-300 rounded-md bg-gray-800 px-3 py-2.5 text-text-primary flex justify-center items-center text-xl absolute top-8 right-4 lg:right-16">
        <h1 class="text-xs text-text-primary">Skip</h1>
        <img class="w-4 ml-1" src="../../assets/icons/rightarrow.svg">
    </div>
    <div class="flex flex-col h-full items-center lg:justify-center w-full mt-16 lg:mt-0 lg:w-[414px]">
        <h1 class="text-text-primary text-[36px] font-bold pb-3">Match History</h1>
        <h2 class="text-text-secondary text-center pb-6">Enter in your match token and authentication code so we can keep track of your matches and help you get better!</h2>
        <div class="flex flex-col gap-6 w-full">
            <div class="w-full flex flex-col rounded-md border border-gray-800 p-6">
                <div class="flex w-full items-center">
                    <h1 class="text-text-primary text-xl font-medium flex-1">Match Token</h1>
                    <a href="https://help.steampowered.com/en/wizard/HelpWithGameIssue/?appid=730&issueid=128&ref=refrag.gg" target="_blank" class="text-primary-400 font-medium text-xs underline">Get it here</a>
                </div>
                <h2 class="text-text-secondary font-light text-sm pt-3 pb-9">Copy and paste the Match Token code from the steam page linked above</h2>
                <div>
                    <input
                        @input="matchToken = $event.target.value"
                        class="block flex-1 rounded-md border-gray-700 bg-gray-800 h-[42px] text-text-secondary text-sm w-full placeholder-gray-500"
                        placeholder="CSGO - XXXXX - XXXXX - XXXXX - XXXXX"
                        type="text"
                        :value="matchToken"
                    />
                </div>
            </div>
            <div class="w-full flex flex-col rounded-md border border-gray-800 p-6">
                <div class="flex w-full items-center">
                    <h1 class="text-text-primary text-xl font-medium flex-1">Authentication Code</h1>
                    <a href="https://help.steampowered.com/en/wizard/HelpWithGameIssue/?appid=730&issueid=128&ref=leetify.com" target="_blank" class="text-primary-400 font-medium text-xs underline">Get it here</a>
                </div>
                <h2 class="text-text-secondary font-light text-sm pt-3 pb-9">Copy and paste the Authentication code from the steam page linked above</h2>
                <div>
                    <input
                        @input="authenticationCode = $event.target.value"
                        class="block flex-1 rounded-md border-gray-700 bg-gray-800 h-[42px] text-text-secondary text-sm w-full placeholder-gray-500"
                        placeholder="XXX - XXXXX - XXXX"
                        type="text"
                        :value="authenticationCode"
                    />
                </div>
            </div>
        </div>
        <button :disabled="!user.steam_id" @click="continueAndSubmit()" class="cursor-pointer group w-full py-3 text-text-primary disabled:text-text-primary/30 bg-primary-600 disabled:bg-primary-600/30 hover:bg-primary-500 mt-10 rounded-md flex items-center justify-center gap-1">
            Continue
            <img class="h-4 brightness-200 group-disabled:brightness-50" src="../../assets/icons/rightarrow.svg" alt="Continue" />
        </button>
    </div>
    <AlertPopup />
</template>

<script setup>
import Pill from '../Pill.vue';
import { onMounted, ref } from 'vue';
import { useAuth } from '@websanova/vue-auth/src/v3';
import { Transition } from 'vue';
import axios from 'axios';
import { CheckCircleIcon } from '@heroicons/vue/solid';
import { useRoute, useRouter } from 'vue-router';
import AlertPopup from '../AlertPopup.vue';
import { useStore } from 'vuex';

const auth = useAuth();
const user = ref(auth.user());
const store = useStore();
const matchToken = ref("");
const authenticationCode = ref("");
const emit = defineEmits(['updateOnboardingStep']);
const route = useRoute();
const router = useRouter();

const props = defineProps({
    currentStep: Number
});

function continueAndSubmit() {
    emit('updateOnboardingStep', { value: props.currentStep + 1 });
    if (matchToken.value) {
        axios({
            method: "post",
            url: "/matches",
            params: {
            match: {
                match_platform: 'valve',
                match_token: matchToken.value,
            }
        },
      })
    }
    if (authenticationCode.value) {
        axios({
            method: "put",
            url: "/users/update_user",
            params: {
                match_auth_token: authenticationCode.value,
            },
        })
    }
}


</script>
